<div class="header">
  <div class="mat-headline-6 accounts-title">Create new account</div>
  @if (!isMobile) {
  <div>
    <a mat-button color="primary" href="https://support.onsip.com" target="_blank">Knowledgebase</a>
  </div>
  }
</div>
<form
  onsipFormTracking
  [formGroup]="createAccountForm"
  name="agent-account-setup"
  id="agent-account-setup"
  (ngSubmit)="submit()"
>
  <mat-horizontal-stepper
    #stepper
    linear
    class="onsip-stepper-disabled-header onsip-header-full-width"
  >
    <mat-step #detailsStep completed="false">
      <ng-template matStepLabel>
        <span>New account details</span>
      </ng-template>
      <ng-container>
        <div class="mat-subtitle-1">New account details</div>
        <ng-template *ngTemplateOutlet="accountDetailsForm"></ng-template>
        <span class="navigation-buttons no-back">
          <button
            mat-button
            type="button"
            color="primary"
            class="cancel-button right-buttons"
            (click)="handleCancel()"
          >
            {{ "ONSIP_I18N.CANCEL" | translate }}
          </button>
          <button
            type="button"
            mat-flat-button
            color="primary"
            class="right-buttons"
            (click)="nextStep('details')"
          >
            {{ "ONSIP_I18N.NEXT" | translate }}
          </button>
        </span>
      </ng-container>
    </mat-step>
    <mat-step #planStep completed="false">
      <ng-template matStepLabel>
        <span>Choose plan</span>
      </ng-template>
      <ng-container>
        <div class="mat-subtitle-1">Choose a plan for your client</div>
        <ng-template *ngTemplateOutlet="accountPlanForm"></ng-template>
        @if (!isMobile) {
        <div class="navigation-buttons">
          <button type="button" mat-stroked-button color="primary" (click)="backStep()">
            {{ "ONSIP_I18N.BACK" | translate }}
          </button>
          <span class="right-action-buttons">
            <button
              mat-button
              type="button"
              color="primary"
              class="cancel-button right-buttons"
              (click)="handleCancel()"
            >
              {{ "ONSIP_I18N.CANCEL" | translate }}
            </button>
            <button
              type="button"
              mat-flat-button
              color="primary"
              class="right-buttons"
              (click)="nextStep('plan')"
            >
              {{ "ONSIP_I18N.NEXT" | translate }}
            </button>
          </span>
        </div>
        } @if (isMobile) {
        <div class="navigation-buttons">
          <button
            mat-button
            type="button"
            color="primary"
            class="cancel-button right-buttons"
            (click)="handleCancel()"
          >
            {{ "ONSIP_I18N.CANCEL" | translate }}
          </button>
          <span>
            <button
              type="button"
              mat-flat-button
              color="primary"
              class="right-buttons"
              (click)="nextStep('plan')"
            >
              {{ "ONSIP_I18N.NEXT" | translate }}
            </button>
            <button type="button" mat-stroked-button color="primary" (click)="backStep()">
              {{ "ONSIP_I18N.BACK" | translate }}
            </button>
          </span>
        </div>
        }
      </ng-container>
    </mat-step>
    <mat-step #sipDomainStep completed="false">
      <ng-template matStepLabel>
        <span>Choose a SIP domain</span>
      </ng-template>
      <ng-container>
        <div class="mat-subtitle-1">Choose a SIP domain for your client</div>
        <ng-template *ngTemplateOutlet="accountSipDomainForm"></ng-template>
        @if (!isMobile) {
        <div class="navigation-buttons">
          <button type="button" mat-stroked-button color="primary" (click)="backStep()">
            {{ "ONSIP_I18N.BACK" | translate }}
          </button>
          <span class="right-action-buttons">
            <button
              mat-button
              type="button"
              color="primary"
              class="cancel-button right-buttons"
              (click)="handleCancel()"
            >
              {{ "ONSIP_I18N.CANCEL" | translate }}
            </button>
            <button type="submit" mat-flat-button color="primary" class="right-buttons">
              Save
            </button>
          </span>
        </div>
        } @if (isMobile) {
        <div class="navigation-buttons">
          <button
            mat-button
            type="button"
            color="primary"
            class="cancel-button right-buttons"
            (click)="handleCancel()"
          >
            {{ "ONSIP_I18N.CANCEL" | translate }}
          </button>
          <span>
            <button type="submit" mat-flat-button color="primary" class="right-buttons">
              Save
            </button>
            <button type="button" mat-stroked-button color="primary" (click)="backStep()">
              {{ "ONSIP_I18N.BACK" | translate }}
            </button>
          </span>
        </div>
        }
      </ng-container>
    </mat-step>
    <!-- ng-template needs to be inside the stepper for template to work-->
    <ng-template #accountDetailsForm let-column>
      <div class="onsip-grid-placement account-details-form-container">
        <div class="first-column mobile-column">
          <mat-form-field appearance="outline" class="form-field">
            <input
              matInput
              #contactName
              type="text"
              formControlName="contactName"
              name="contactName"
            />
            <mat-label>Contact name</mat-label>
            <mat-error>{{ getNameError() }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-field">
            <input
              matInput
              #companyName
              type="text"
              formControlName="companyName"
              name="companyName"
            />
            <mat-label>Company name</mat-label>
            <mat-error>{{ getCompanyError() }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-field">
            <input
              matInput
              #contactEmail
              type="text"
              formControlName="contactEmail"
              name="contactEmail"
            />
            <mat-label>Contact email</mat-label>
            <mat-error>{{ getEmailError() }}</mat-error>
          </mat-form-field>
        </div>
        <div class="second-column mobile-column">
          <mat-form-field appearance="outline" class="form-field">
            <input
              matInput
              #contactPhoneNumber
              type="text"
              formControlName="contactPhoneNumber"
              name="contactPhoneNumber"
            />
            <mat-label>Contact phone number</mat-label>
            <mat-error>{{ getPhoneError() }}</mat-error>
          </mat-form-field>
        </div>
        <div class="third-column mobile-column">
          <mat-form-field appearance="outline" class="form-field">
            <input
              matInput
              onsipAddressValidator
              #primaryAddress
              type="text"
              formControlName="primaryAddress"
              name="primaryAddress"
            />
            <mat-label>Address 1</mat-label>
            @for (error of createAccountForm.controls.primaryAddress.errors | keyvalue; track error)
            {
            <mat-error>
              {{ error.value.message }}
            </mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline" class="form-field">
            <input
              matInput
              #secondaryAddress
              type="text"
              formControlName="secondaryAddress"
              name="secondaryAddress"
            />
            <mat-label>Address 2</mat-label>
          </mat-form-field>
        </div>
        @if (!isMobile) {
        <div class="fourth-column">
          <div class="city-state-container">
            <mat-form-field appearance="outline" class="form-field city-field">
              <input
                matInput
                onsipCityValidator
                #city
                type="text"
                formControlName="city"
                name="city"
              />
              <mat-label>City</mat-label>
              @for (error of createAccountForm.controls.city.errors | keyvalue; track error) {
              <mat-error>
                {{ error.value.message }}
              </mat-error>
              }
            </mat-form-field>
            <mat-form-field appearance="outline" onsip-form-field-infix-auto class="state-field">
              <!-- HACK for autocompleting state form field -->
              <input
                class="hide-for-autocomplete"
                type="text"
                name="state"
                [formControl]="autoCompleteState"
                autocomplete="address-level1"
              />
              <mat-select onsipStateValidator formControlName="state" [placeholder]="'State'">
                @for (state of listOfStates; track state) {
                <mat-option [value]="state[1]">
                  {{ state[1] }}
                </mat-option>
                }
              </mat-select>
              @for (error of createAccountForm.controls.state.errors | keyvalue; track error) {
              <mat-error>
                {{ error.value.message }}
              </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        } @if (isMobile) {
        <div class="fourth-column mobile-column">
          <mat-form-field appearance="outline" class="form-field city-field">
            <input
              matInput
              onsipCityValidator
              #city
              type="text"
              formControlName="city"
              name="city"
            />
            <mat-label>City</mat-label>
            @for (error of createAccountForm.controls.city.errors | keyvalue; track error) {
            <mat-error>
              {{ error.value.message }}
            </mat-error>
            }
          </mat-form-field>
        </div>
        } @if (!isMobile) {
        <div class="fifth-column">
          <mat-form-field appearance="outline" onsip-form-field-infix-auto>
            <input
              matInput
              onsipZipCodeValidator
              autocomplete="postal-code"
              formControlName="zipCode"
            />
            <mat-label>Zip code</mat-label>
            @for (error of createAccountForm.controls.zipCode.errors | keyvalue; track error) {
            <mat-error>
              {{ error.value.message }}
            </mat-error>
            }
          </mat-form-field>
        </div>
        } @if (isMobile) {
        <div class="fifth-column mobile-column">
          <div class="state-zip-container">
            <mat-form-field appearance="outline" onsip-form-field-infix-auto class="state-field">
              <!-- HACK for autocompleting state form field -->
              <input
                class="hide-for-autocomplete"
                type="text"
                name="state"
                [formControl]="autoCompleteState"
                autocomplete="address-level1"
              />
              <mat-select onsipStateValidator formControlName="state" [placeholder]="'State'">
                @for (state of listOfStates; track state) {
                <mat-option [value]="state[1]">
                  {{ state[1] }}
                </mat-option>
                }
              </mat-select>
              @for (error of createAccountForm.controls.state.errors | keyvalue; track error) {
              <mat-error>
                {{ error.value.message }}
              </mat-error>
              }
            </mat-form-field>
            <mat-form-field appearance="outline" class="zip-field" onsip-form-field-infix-auto>
              <input
                matInput
                onsipZipCodeValidator
                autocomplete="postal-code"
                formControlName="zipCode"
              />
              <mat-label>Zip code</mat-label>
              @for (error of createAccountForm.controls.zipCode.errors | keyvalue; track error) {
              <mat-error>
                {{ error.value.message }}
              </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        }
      </div>
    </ng-template>
    <ng-template #accountPlanForm let-column>
      @if (!isMobile) {
      <div class="plan-cards onsip-grid-placement">
        <mat-card class="pay-as-you-go-card">
          <div>
            <ng-container>
              <div class="mat-headline-6">Pay as you go</div>
              <mat-card-content class="card-content">
                <div class="priceline mat-body-1">{{ payAsYouGoPrice }}/month per account</div>
                <div>Built for users with a lower call volume</div>
              </mat-card-content>
            </ng-container>
          </div>
          <mat-radio-group color="primary" formControlName="payAsYouGoPlan">
            <mat-radio-button [value]="true" onsip-mat-radio-button-label-align-normal
              >Select</mat-radio-button
            >
          </mat-radio-group>
        </mat-card>
        <mat-card class="unlimited-card">
          <div>
            <ng-container>
              <div class="mat-headline-6">Unlimited</div>
              <mat-card-content class="card-content">
                <div class="priceline mat-body-1">{{ unlimitedPrice }}/month per user</div>
                <div class="mat-card-text">
                  All-in-one business phone system with free desk phones or headsets
                </div>
              </mat-card-content>
            </ng-container>
          </div>
          <mat-radio-group color="primary" formControlName="unlimitedPlan">
            <mat-radio-button [value]="true" onsip-mat-radio-button-label-align-normal
              >Select</mat-radio-button
            >
          </mat-radio-group>
        </mat-card>
      </div>
      } @if (isMobile) {
      <div class="plan-cards">
        <mat-card class="pay-as-you-go-card">
          <div>
            <ng-container>
              <div class="card-title">Pay as you go</div>
              <mat-card-content class="card-content">
                <div class="priceline mat-body-1">{{ payAsYouGoPrice }}/month per account</div>
                <div>Built for users with a lower call volume</div>
              </mat-card-content>
            </ng-container>
          </div>
          <mat-radio-group color="primary" formControlName="payAsYouGoPlan">
            <mat-radio-button [value]="true" onsip-mat-radio-button-label-align-normal
              >Select</mat-radio-button
            >
          </mat-radio-group>
        </mat-card>
        <mat-card class="unlimited-card">
          <div>
            <ng-container>
              <div class="card-title">Unlimited</div>
              <mat-card-content class="card-content">
                <div class="priceline mat-body-1">{{ unlimitedPrice }}/month per user</div>
                <div class="mat-card-text">
                  All-in-one business phone system with free desk phones or headsets
                </div>
              </mat-card-content>
            </ng-container>
          </div>
          <mat-radio-group color="primary" formControlName="unlimitedPlan">
            <mat-radio-button [value]="true" onsip-mat-radio-button-label-align-normal
              >Select</mat-radio-button
            >
          </mat-radio-group>
        </mat-card>
      </div>
      }
    </ng-template>
    <ng-template #accountSipDomainForm let-column>
      <div class="domain-form-container onsip-grid-placement">
        <div class="domain-desc-container">
          <div class="mat-body-2">
            For example, if your client is AcmeCorp, you might choose the domain
            acmecorp.onsip.com.You can only use the following characters:
          </div>
          <ul class="mat-body-2">
            <li>Letters</li>
            <li>Number</li>
            <li>Dashes</li>
          </ul>
        </div>
        <section class="domain-input-container with-mat-form-field">
          <mat-form-field
            class="onsip-form-field-same-height-suffix onsip-hack-responsive-suffix"
            appearance="outline"
          >
            <mat-label>Domain</mat-label>
            <input
              matInput
              #domain
              type="text"
              [placeholder]="'Mycustomdomainname'"
              formControlName="domain"
              name="domain"
            />
            <span matSuffix>.onsip.com</span>
            <mat-error>{{ getDomainError() }}</mat-error>
          </mat-form-field>
        </section>
      </div>
    </ng-template>
  </mat-horizontal-stepper>
</form>
