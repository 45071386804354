import { Component, inject } from "@angular/core";

import { entryViews } from "./entry-views";
import { Router } from "@angular/router";
import { map } from "rxjs";

@Component({
  selector: "onsip-entry-page",
  templateUrl: "./entry-page.component.html",
  styleUrls: ["./entry-page.scss"]
})
export class EntryPageComponent {
  router = inject(Router);

  entryViews = entryViews;

  showContactSupport = this.router.events.pipe(
    map(() => {
      return this.router.url !== entryViews.CONFIRM_MFA;
    })
  );
}
