import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef
} from "@angular/core";
import { AccountService } from "@onsip/common/services/api/resources/account/account.service";
import { Subscription, take, filter } from "rxjs";
import { views } from "@onsip/web/app/phone/views";
import { Validators, FormControl, NonNullableFormBuilder } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { listOfStates } from "@onsip/common/interfaces/state";
import phoneNumberValidator from "@onsip/common/libraries/validators/phone-number-validators";
import { Router } from "@angular/router";
import { ModalMaterialComponent } from "@onsip/web/features/shared/components/modal/modal-material.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { BASIC_PLAN_PACKAGE_ID } from "@onsip/common/services/api/resources/accountInvoice/account-invoice-pretend.service";
import { SnackbarService } from "@onsip/web/features/shared/components/snackbar/snackbar.service";
import { AddAccountContacts } from "@onsip/common/services/api/resources/account/account";
import { AccountInvoicePretendService } from "@onsip/common/services/api/resources/accountInvoice/account-invoice-pretend.service";
import { DisplayPackageName } from "@onsip/common/services/api/resources/package/package";
import { PackageInvoiceItem } from "@onsip/common/services/api/resources/accountInvoice/account-invoice-item";
import { currencyFormatter } from "@onsip/web/features/shared/helpers/currency-formatter";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { SignupValidateDomainService } from "@onsip/common/services/api/resources/SignupValidateDomainService/signupValidateDomain.service";
import { NAME_VALIDATOR_REGEX } from "@onsip/common/libraries/validators/name-validators";

@Component({
  selector: "onsip-agent-accounts-create-account",
  templateUrl: "./agent-accounts-create-account.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./agent-accounts-create-account.component.scss"]
})
export class AgentAdminAccountsCreateAccountComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;
  @ViewChild("stepper") stepper!: MatStepper;

  views = views;
  listOfStates = listOfStates;
  unlimitedPrice!: string;
  payAsYouGoPrice!: string;
  isMobile = false;
  createAccountForm = this.fb.group({
    contactName: [
      "",
      [Validators.required, Validators.maxLength(60), Validators.pattern(NAME_VALIDATOR_REGEX)]
    ],
    companyName: ["", [Validators.maxLength(60), Validators.pattern(NAME_VALIDATOR_REGEX)]],
    contactEmail: [
      "",
      [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(128)]
    ],
    contactPhoneNumber: ["", [Validators.required, phoneNumberValidator()]],
    primaryAddress: ["", [Validators.required]],
    secondaryAddress: [""],
    city: ["", [Validators.required]],
    state: ["", [Validators.required]],
    zipCode: ["", [Validators.required]],
    payAsYouGoPlan: [false],
    unlimitedPlan: [false],
    domain: [
      "",
      [
        Validators.required,
        // regex: alphanumeric & dash, no double dash or start/end dash
        Validators.pattern("^[a-z][a-z0-9]*(?:-[a-z0-9]+)*$"),
        Validators.maxLength(24)
      ]
    ]
  });

  /** hidden form field solely used for autocompleting user's state */
  autoCompleteState = new FormControl();

  private unsubscriber = new Subscription();

  constructor(
    private fb: NonNullableFormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private accountService: AccountService,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private accountInvoicePretendService: AccountInvoicePretendService,
    private validateDomainService: SignupValidateDomainService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initPricing();
    this.setBreakpoints();
    this.setPlanSelectHandler();
    this.setAutoCompleteState();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  getNameError(): string {
    const control = this.createAccountForm.controls.contactName;
    const value = this.translate.instant("ONSIP_I18N.FULL_NAME");
    return control.hasError("required")
      ? this.translate.instant("ONSIP_I18N._VALUE__IS_REQUIRED", { value })
      : control.hasError("maxlength")
      ? this.translate.instant("ONSIP_I18N._VALUE__IS_LONGER_THAN_60_CHARACTERS", { value })
      : control.hasError("pattern")
      ? this.translate.instant(
          "ONSIP_I18N._VALUE__CAN_ONLY_CONTAIN_LETTERS_SPACES_QUOTES_OR_DASHES",
          { value }
        )
      : "";
  }

  getCompanyError(): string {
    const control = this.createAccountForm.controls.companyName;
    const value = "Company name";
    return control.hasError("maxlength")
      ? this.translate.instant("ONSIP_I18N._VALUE__IS_LONGER_THAN_60_CHARACTERS", { value })
      : control.hasError("pattern")
      ? this.translate.instant(
          "ONSIP_I18N._VALUE__CAN_ONLY_CONTAIN_LETTERS_SPACES_QUOTES_OR_DASHES",
          { value }
        )
      : "";
  }

  getEmailError(): string {
    const control = this.createAccountForm.controls.contactEmail;
    return control.hasError("required")
      ? this.translate.instant("ONSIP_I18N.EMAIL_IS_REQUIRED")
      : control.hasError("email")
      ? this.translate.instant("ONSIP_I18N.INVALID_EMAIL")
      : "";
  }

  getPhoneError(): string {
    const control = this.createAccountForm.controls.contactPhoneNumber;
    return control.hasError("required")
      ? this.translate.instant("ONSIP_I18N.PHONE_NUMBER_IS_REQUIRED")
      : control.hasError("validPhoneNumber")
      ? this.translate.instant("ONSIP_I18N.MUST_BE_A_VALID_10DIGIT_PHONE_NUMBER")
      : "";
  }

  getDomainError(): string {
    const control = this.createAccountForm.controls.domain;
    return control.hasError("pattern")
      ? this.translate.instant(
          "ONSIP_I18N.DOMAIN_MUST_BEGIN_WITH_A_LOWERCASE_LETTER_AND_MAY_CONTAIN_ONLY_LOWERCASE_LETTERS_NUMBERS_AND_SINGLE_NONCONSECUTIVE_DASHES"
        )
      : control.hasError("maxlength")
      ? this.translate.instant("ONSIP_I18N.CHARACTER_LIMIT_REACHED")
      : control.hasError("domainUnavailable")
      ? this.translate.instant("ONSIP_I18N.DOMAIN_IS_UNAVAILABLE")
      : control.hasError("required")
      ? this.translate.instant("ONSIP_I18N.DOMAIN_IS_REQUIRED")
      : "";
  }

  handleCancel(): void {
    this.unsubscriber.add(
      this.dialog
        .open(ModalMaterialComponent, {
          panelClass: ["mat-typography", "onsip-dialog-universal-style"],
          data: {
            title: "Cancel setup?",
            message: this.translate.instant(
              "ONSIP_I18N.ANY_CHANGES_YOU_HAVE_MADE_WILL_NOT_BE_SAVED"
            ),
            primaryBtnText: this.translate.instant("ONSIP_I18N.OK"),
            secondaryBtnText: this.translate.instant("ONSIP_I18N.KEEP_EDITING"),
            primaryBtnFlat: true
          }
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(retObj => {
          if (retObj && retObj.doPrimaryAction) {
            this.router.navigate([views.AGENT_ACCOUNTS]);
          }
        })
    );
  }

  nextStep(currentStep: "details" | "plan"): void {
    if (currentStep === "details") {
      if (!this.detailsValid()) {
        return;
      }
    }
    if (this.stepper.selected) this.stepper.selected.completed = true;
    this.stepper.next();
  }

  backStep(): void {
    this.stepper.previous();
  }

  submit(): void {
    this.validateDomainService
      .signupValidateDomain(this.createAccountForm.controls.domain.value + ".onsip.com")
      .then(res => {
        if (res.status === "success") {
          this.execAddAccount();
        } else {
          if (res.data.code === "Domain.Unavailable") {
            this.createAccountForm.controls.domain.setErrors({ domainUnavailable: true });
          }
          this.showErrorSnackbar("Please fix errors in your submission.");
          this.cdRef.markForCheck();
          return;
        }
      });
  }

  private setAutoCompleteState(): void {
    this.unsubscriber.add(
      this.autoCompleteState.valueChanges.subscribe(value => {
        if (value.length !== 2) {
          const stateTuple = listOfStates.find(tup => tup[0] === value);
          value = stateTuple ? stateTuple[1] : "";
        }
        this.createAccountForm.controls.state.setValue(value);
        this.cdRef.markForCheck();
      })
    );
  }

  private execAddAccount(): void {
    if (!this.createAccountForm.valid) {
      this.showErrorSnackbar("Please fix errors in your submission.");
      return;
    }
    const domain = this.createAccountForm.controls.domain.value.toLocaleLowerCase();
    const Username = domain.replace(/-/g, "_");
    const params: AddAccountContacts = {
      Name: this.createAccountForm.controls.contactName.value,
      Company: this.createAccountForm.controls.companyName.value,
      Email: this.createAccountForm.controls.contactEmail.value,
      Phone: this.createAccountForm.controls.contactPhoneNumber.value,
      Address:
        this.createAccountForm.controls.primaryAddress.value +
        " " +
        this.createAccountForm.controls.secondaryAddress.value,
      City: this.createAccountForm.controls.city.value,
      State: this.createAccountForm.controls.state.value,
      Zipcode: this.createAccountForm.controls.zipCode.value,
      Domain: domain + ".onsip.com",
      Username
    };
    if (this.createAccountForm.controls.payAsYouGoPlan.value) {
      this.addBasicAccount(params);
    } else {
      this.addUnlimitedAccount(params);
    }
    this.router.navigate([views.AGENT_ACCOUNTS]);
  }

  private setBreakpoints(): void {
    this.unsubscriber.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe(result => {
        this.isMobile = result.matches;
        this.cdRef.markForCheck();
      })
    );
  }
  private initPricing(): void {
    this.accountInvoicePretendService.getBasicPlanInvoicePretend();
    this.accountInvoicePretendService.getUnlimitedPlanInvoicePretend();
    this.unsubscriber.add(
      this.accountInvoicePretendService.state
        .pipe(filter(state => state.length > 0 && !state.loading))
        .subscribe(state => {
          const pretendInvoices = Object.values(state.state);
          const unlimitedPlan = pretendInvoices.find(
            invoice => invoice.invoiceType === DisplayPackageName.UNLIMITED_PLAN
          );
          const basicPlan = pretendInvoices.find(
            invoice => invoice.invoiceType === DisplayPackageName.BASIC_PLAN
          );
          if (unlimitedPlan) {
            const packages = unlimitedPlan.itemGroups.find(item => item.type === "Package");
            const userPackage = (packages?.item as Array<PackageInvoiceItem>)?.find(item =>
              item.packageItem.packageName.match(/User-1\.[4567]-(\d+)/)
            );
            if (userPackage) {
              const numOfUser = parseInt(
                userPackage.packageItem.packageName.replace(/User-1\.[4567]-/, "")
              );
              this.unlimitedPrice = currencyFormatter(
                (parseFloat(userPackage.amount) / (numOfUser < 5 ? 5 : numOfUser)) // we charge a minimum of 5 users for unlimited plan
                  .toString()
              );
            } else {
              this.unlimitedPrice = "undetermined";
            }
          }
          if (basicPlan) {
            const packages = basicPlan.itemGroups.find(item => item.type === "Package");
            const basicPackage = (packages?.item as Array<PackageInvoiceItem>)?.find(
              item => item.packageItem.packageDescription === "Pay As You Go Plan"
            );
            this.payAsYouGoPrice = basicPackage
              ? currencyFormatter(basicPackage.amount)
              : "undetermined";
          }
          this.cdRef.markForCheck();
        })
    );
  }

  private detailsValid(): boolean {
    this.createAccountForm.markAllAsTouched();
    this.createAccountForm.controls.domain.markAsUntouched();
    return (
      this.createAccountForm.controls.contactName.valid &&
      this.createAccountForm.controls.companyName.valid &&
      this.createAccountForm.controls.contactEmail.valid &&
      this.createAccountForm.controls.contactPhoneNumber.valid &&
      this.createAccountForm.controls.primaryAddress.valid &&
      this.createAccountForm.controls.secondaryAddress.valid &&
      this.createAccountForm.controls.city.valid &&
      this.createAccountForm.controls.state.valid &&
      this.createAccountForm.controls.zipCode.valid
    );
  }

  private setPlanSelectHandler(): void {
    this.createAccountForm.controls.payAsYouGoPlan.setValue(true);
    this.unsubscriber.add(
      this.createAccountForm.controls.payAsYouGoPlan.valueChanges.subscribe(value =>
        this.createAccountForm.controls.unlimitedPlan.setValue(!value, { emitEvent: false })
      )
    );
    this.unsubscriber.add(
      this.createAccountForm.controls.unlimitedPlan.valueChanges.subscribe(value =>
        this.createAccountForm.controls.payAsYouGoPlan.setValue(!value, { emitEvent: false })
      )
    );
  }

  private addBasicAccount(params: AddAccountContacts): void {
    this.accountService
      .agentAddAccount({
        PackageId: BASIC_PLAN_PACKAGE_ID.toString(),
        ...params
      })
      .then(res => {
        if (res.status === "success") {
          this.showSuccessSnackbar();
        } else {
          this.showErrorSnackbar(res.data.message);
          this.accountService.clearErrors();
        }
      });
  }

  private addUnlimitedAccount(params: AddAccountContacts): void {
    this.accountService
      .agentAddAccount({
        PerUserPricing: "unlimited",
        ...params
      })
      .then(res => {
        if (res.status === "success") {
          this.showSuccessSnackbar();
        } else {
          this.showErrorSnackbar(res.data.message);
          this.accountService.clearErrors();
        }
      });
  }

  private showSuccessSnackbar(): void {
    this.snackbar.openSnackBar(
      `${this.createAccountForm.controls.companyName.value} has been successfully created!`,
      "success"
    );
  }

  private showErrorSnackbar(message: string): void {
    this.snackbar.openSnackBar(message, "error");
  }
}
