import { Component, NgZone, ViewChild, ElementRef, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, timer, Subscription, lastValueFrom } from "rxjs";
import { take, takeUntil, pluck, filter, pairwise } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { Config } from "../../../../../../common/config";

import { ApiSessionService } from "../../../../../../common/services/api/api-session.service";
import { Oauth2AuthService } from "../../../services/oauth2/oauth2-auth.service";
import { OAuthPresencePublisherService } from "../../../../../../common/services/oauthFirebase/oauth-presence-publisher.service";
import { OAuthPresenceListenerService } from "../../../../../../common/services/oauthFirebase/oauth-presence-listener.service";
import { SupportService } from "../../../../../../common/services/support/support.service";
import { prephoneViews } from "../prephone-views";
import { EntryFormErrorStateMatcher } from "../../entryPage/entry-form-error-state-matcher";
import { ConnectionIssueModalComponent } from "../modal/connection-issue.component";
import { E2eLocators } from "@onsip/e2e/configs/locators";

import { initializeApp } from "../../../../../libraries/firebase/app";
import { UUID } from "../../../../../../common/libraries/sip/uuid";
import { Role } from "@onsip/common/services/api/role";
import { views } from "@onsip/web/app/phone/views";
import { LoginErrorCode } from "@onsip/common/services/api/login-error";

@Component({
  selector: "onsip-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.scss"]
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @ViewChild("loginUsername") usernameField!: ElementRef;
  @ViewChild("loginPassword") passwordField!: ElementRef;
  E2eLocators = E2eLocators;

  hideOauth = false;
  userList: Array<any> = [];
  prephoneViews: any = prephoneViews;

  loading = new BehaviorSubject<boolean>(false);
  oauthSignin = new BehaviorSubject<boolean>(false);

  usernameControl: FormControl;
  usernameErrorStateMatcher: EntryFormErrorStateMatcher;
  passwordControl: FormControl;
  passwordErrorStateMatcher: EntryFormErrorStateMatcher;
  cleanServiceName = "";
  selectedUser: any;

  private userOAuth2AccessToken: any;
  private unsubscriber = new Subscription();

  constructor(
    private zone: NgZone,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private apiSession: ApiSessionService,
    private oauth2AuthService: Oauth2AuthService,
    private supportService: SupportService,
    private oauthPresenceListener: OAuthPresenceListenerService,
    private oauthPresencePublisher: OAuthPresencePublisherService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.usernameControl = new FormControl("");
    this.usernameErrorStateMatcher = new EntryFormErrorStateMatcher();
    this.passwordControl = new FormControl("");
    this.passwordErrorStateMatcher = new EntryFormErrorStateMatcher();
  }

  errorFunc(error: any, isOffline?: boolean): void {
    let message = "";
    this.loading.next(false);
    if (error && error.message === "Login failed - MfaEmail.Check") {
      message = "Please check your email to confirm your device and location";
    } else if (error && error.code === LoginErrorCode.BetaRestricted) {
      message = error.message;
      this.setFormErrors();
    } else {
      this.setFormErrors();
      if (isOffline) {
        message = this.translate.instant("ONSIP_I18N.NETWORK_NOT_DETECTED");
      } else {
        message = this.translate.instant("ONSIP_I18N.INVALID_USERNAME_OR_PASSWORD");
        this.passwordField.nativeElement.focus();
      }
    }

    this.snackBar.open(message, "", {
      duration: 7000
    });
  }

  ngOnInit(): void {
    this.platformInitLogin();
    this.platformInitFirebase();
    this.checkBrowserSupport();
    if (!Config.IS_DESKTOP) {
      this.postLoginSuperAdminRouting();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  /**
   * 3 Cases:
   *  On Web:
   *    platformOAuthLogin
   *      Do OAuth normally, return the key
   *    handleOAuthLogin
   *      Handle OAuth normally, leads to logging in and going to the rest of the app
   *
   *  On Desktop (Google only, otherwise see above):
   *    platformOAuthLogin
   *      Set up firebase listener, wait for token to come from there, return the key
   *    handleOAuthLogin
   *      Handle OAuth normally, leads to logging in and going to the rest of the app
   *
   *  On Web where the "ofk" (oauth firebase key) query param is supplied:
   *    platformOAuthLogin
   *      Do OAuth normally, return the key
   *    handleOAuthLogin
   *      Publish the key to firebase
   */
  oauthLogin(service: string): void {
    if (navigator.onLine) {
      this.loading.next(true);
      this.platformOAuthLogin(service)
        .then(authCode => this.handleOAuthLogin(service, authCode))
        .catch(() => {
          const message = this.translate.instant(
            "ONSIP_I18N.LOGIN_WITH__VALUE__DID_NOT_COMPLETE_SUCCESSFULLY",
            { value: service }
          );
          this.snackBar.open(message, "", { duration: 7000 });
          this.oauthSignin.next(false);
          this.loading.next(false);
        });
    } else {
      this.errorFunc(undefined, true);
    }
  }

  completeOAuthLogin(): void {
    this.loading.next(true);

    this.apiSession.oAuthSelectUser(this.selectedUser.UserId, this.userOAuth2AccessToken);

    this.platformSaveOAuthCredentials(
      this.cleanServiceName + "Login",
      this.userOAuth2AccessToken.Token,
      this.userOAuth2AccessToken.ServiceUserId,
      this.selectedUser.UserId
    );
  }

  platformLoggedIn(username: string, password: string): void {
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("logged-in", { username, password });
    }
  }

  platformSaveOAuthCredentials(
    serviceName: string,
    token: any,
    serviceUserId: string,
    userId: string
  ): void {
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("oauth-logged-in", { serviceName, token, serviceUserId, userId });
    }
  }

  submit(event: Event): void {
    const username: string = this.usernameField.nativeElement.value.toLowerCase().trim(),
      password: string = this.passwordField.nativeElement.value.trim();

    event.preventDefault();
    if (this.loading.value) return;

    let message = "";

    if (username.length === 0 && password.length === 0) {
      this.setFormErrors();
      message = this.translate.instant("ONSIP_I18N.SIP_ADDRESS_AND_PASSWORD_ARE_REQUIRED");
      this.usernameField.nativeElement.focus();
    } else if (username.length === 0 || password.length === 0) {
      if (username.length === 0) {
        this.usernameControl.setErrors({
          invalid: true
        });
        message = this.translate.instant("ONSIP_I18N.SIP_ADDRESS_IS_REQUIRED");
        this.usernameField.nativeElement.focus();
      }
      if (password.length === 0) {
        this.passwordControl.setErrors({
          invalid: true
        });
        message = this.translate.instant("ONSIP_I18N.PASSWORD_IS_REQUIRED");
        this.passwordField.nativeElement.focus();
      }
    } else if (username.endsWith("@getonsip.com")) {
      this.usernameControl.setErrors({
        invalid: true
      });
      message = this.translate.instant("ONSIP_I18N.GETONSIP_ADDRESSES_ARE_NO_LONGER_PERMITTED");
      this.usernameField.nativeElement.focus();
    }

    if (message.length > 0) {
      this.snackBar.open(message, "", {
        duration: 7000
      });
      return;
    }

    this.loading.next(true);

    if (navigator.onLine) {
      this.apiSession
        .login(username, password)
        .then(() => {
          this.loading.next(false);
          this.platformLoggedIn(username, password);
        })
        .catch(e => {
          this.errorFunc(e);
        });
    } else {
      this.errorFunc(undefined, true);
    }
  }

  resetErrors(event?: MouseEvent): void {
    const typeHack: any = event && event.target;
    typeHack && typeHack.previousElementSibling.select();
    if (this.passwordControl.invalid || this.usernameControl.invalid) {
      this.passwordControl.updateValueAndValidity();
      this.usernameControl.updateValueAndValidity();
    }
  }

  private platformInitLogin(): void {
    if (Config.IS_DESKTOP) {
      window.electron.onMessage("is-app-store", () => {
        Config.isAppStore = true;
        this.hideOauth = true;
      });

      window.electron.onMessage("signin-user", (event, credentials) => {
        if (navigator.onLine) {
          this.loading.next(true);
          this.apiSession
            .login(credentials[0], credentials[1])
            .then(() => {
              this.zone.run(() => this.loading.next(false));
            })
            .catch(() => {
              this.zone.run(() => this.loading.next(false));
            });
        } else {
          this.errorFunc(undefined, true);
        }
      });

      window.electron.onMessage("oauth-signin-user", (event, credentials) => {
        const tokenObj: any = {
          Service: credentials[0],
          Token: credentials[1],
          ServiceUserId: credentials[2],
          UserId: credentials[3]
        };

        if (navigator.onLine) {
          this.apiSession.oAuthRecoverSession(tokenObj);
        } else {
          this.errorFunc(undefined, true);
        }
      });

      window.electron.sendMessage("loginForm-loaded");
    }
  }

  private platformInitFirebase(): void {
    if (Config.IS_DESKTOP) {
      initializeApp(["auth", "database", "firestore"]);
    } else {
      this.unsubscriber.add(
        this.route.queryParams.pipe(take(1)).subscribe(params => {
          // ofk = oauth firebase key
          if (params.ofk) {
            this.loading.next(true);
            initializeApp("database");
            this.oauthLogin("Google");
          }
        })
      );
    }
  }

  private platformOAuthLogin(service: string): Promise<string> {
    if (Config.IS_DESKTOP && service === "Google") {
      const id = UUID.randomUUID();
      const delegateUrl = `http://app.onsip.com/app/#/login?ofk=${id}`;
      window.open(delegateUrl, "_blank");
      this.oauthPresenceListener.start(id);
      return lastValueFrom(
        this.oauthPresenceListener.state.pipe(
          pluck("token"),
          filter(<T>(token: T): token is NonNullable<T> => !!token),
          take(1),
          takeUntil(timer(3 * 60000)) // crap out after 3m, authToken will be undefined
        )
      ).then(authToken => {
        this.oauthPresenceListener.stop();
        // in order to clear token, start and immediatley stop a publisher at the same key
        this.oauthPresencePublisher.start(id, "");
        this.oauthPresencePublisher.stop();
        window.electron.sendMessage("do-focus");
        if (!authToken) {
          throw new Error("Did not receive authToken from firebase within 3 minutes");
        }
        return authToken;
      });
    } else {
      return this.oauth2AuthService.login(service + "Login");
    }
  }

  private handleOAuthLogin(service: string, authCode: string): Promise<void> {
    return lastValueFrom(this.route.queryParams.pipe(take(1))).then(params => {
      // ofk = oauth firebase key
      if (params.ofk) {
        this.oauthPresencePublisher.start(params.ofk, authCode);
        this.oauthPresenceListener.start(params.ofk);
        // when desktop gets the token it will tear down the firebase node - we detect this here and close the window because we're done
        this.unsubscriber.add(
          this.oauthPresenceListener.state
            .pipe(
              pairwise(),
              filter(([prev, next]) => !!prev.token && !next.token)
            )
            .subscribe(() => window.close())
        );
        setTimeout(() => window.close(), 10000); // crap out after 10s just in case
      } else {
        return this.apiSession.oAuthLogin(authCode, service + "Login").then(response => {
          if (response.Result) {
            const users: any = response.Result.OAuth2SessionCreate.UserSummaries.UserSummary;

            if (users.length === 0) {
              this.oauthSignin.next(false);
              this.hideOauth = true;
              const message = this.translate.instant(
                "ONSIP_I18N.PLEASE_CONTINUE__VALUE__SIGN_IN_BY_LOGGING_INTO_ONSIP_NORMALLY",
                { value: service }
              );
              this.snackBar.open(message, "", { duration: 7000 });
            } else if (users.length > 1) {
              this.oauthSignin.next(true);
              this.userList = users;
              this.userOAuth2AccessToken =
                response.Result.OAuth2SessionCreate.UserOAuth2AccessToken;
              this.cleanServiceName = this.userOAuth2AccessToken.Service.replace(
                /Login|Dummy/g,
                ""
              );
              this.selectedUser = undefined;
            } else {
              this.userOAuth2AccessToken =
                response.Result.OAuth2SessionCreate.UserOAuth2AccessToken;
              this.cleanServiceName = this.userOAuth2AccessToken.Service.replace(
                /Login|Dummy/g,
                ""
              );
              this.platformSaveOAuthCredentials(
                this.cleanServiceName + "Login",
                this.userOAuth2AccessToken.Token,
                this.userOAuth2AccessToken.ServiceUserId,
                users[0].UserId
              );

              // save response.Result.OAuth2SessionCreate.UserOAuth2AccessToken;
              // save cleanServiceName + login
              // save UserId users[0].UserId
            }
          }
          this.loading.next(false);
        });
      }
    });
  }

  private checkBrowserSupport(): void {
    const browserInfo = this.supportService.getBrowser();
    if (!browserInfo.isSupported) {
      this.dialog.open(ConnectionIssueModalComponent, {
        disableClose: true,
        closeOnNavigation: false,
        panelClass: ["mat-typography", "onsip-dialog-universal-style"]
      });
    }
  }

  private postLoginSuperAdminRouting(): void {
    this.unsubscriber.add(
      this.apiSession.state
        .pipe(
          filter(state => !!state.loggedIn),
          take(1)
        )
        .subscribe(state => {
          const role = state.role;
          if (role === Role.SuperUser) {
            this.router.navigate([views.SUPER_USER_VIEW]);
          } else if (role === Role.AgentAdmin || role === Role.SubAgentAdmin) {
            this.router.navigate([views.AGENT_VIEW]);
          }
        })
    );
  }

  private setFormErrors() {
    this.usernameControl.setErrors({
      invalid: true
    });
    this.passwordControl.setErrors({
      invalid: true
    });
  }
}
