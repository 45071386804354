<onsip-header-main>
  <onsip-header-page-title>{{ "ONSIP_I18N.SUPPORT" | translate }}</onsip-header-page-title>
</onsip-header-main>
<div class="onsip-grid-placement">
  <div class="top-row-container onsip-grid-placement">
    <div class="contact-support">
      <onsip-admin-support-contact-support></onsip-admin-support-contact-support>
    </div>
    <div class="submit-ticket">
      <onsip-admin-support-submit-ticket></onsip-admin-support-submit-ticket>
    </div>
  </div>
  <div class="view-support-tickets">
    <!-- Insert support ticket component here-->
    <onsip-support-tickets></onsip-support-tickets>
  </div>
  <div class="support-faq-section">
    <div class="onsip-support-faq-body">
      <div class="faq-header-container">
        <h2>FAQs</h2>
        <div class="mat-body-1">
          <button mat-flat-button color="primary" (click)="openKnowledgeBase()">
            Knowledgebase
          </button>
        </div>
        <mat-divider onsip-solid-divider></mat-divider>
      </div>
      @if (!isSmallOrMedBreakpoint) {
      <ul class="onsip-support-faq-list mat-body-2 onsip-grid-placement">
        <div class="first-column faq-column">
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/360026198551-SMS-Text-Messaging"
              target="_blank"
            >
              {{ "ONSIP_I18N.SMS_TEXT_MESSAGING" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/360015918652-Unable-to-Access-Phone-s-Web-Interface"
              target="_blank"
            >
              {{ "ONSIP_I18N.UNABLE_TO_ACCESS_PHONES_WEB_INTERFACE" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/115003351352-How-to-Recover-a-SIP-Address"
              target="_blank"
            >
              {{ "ONSIP_I18N.HOW_TO_RECOVER_A_SIP_ADDRESS" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/115002597511-Mimecast-email-blocking"
              target="_blank"
            >
              {{ "ONSIP_I18N.MIMECAST_EMAIL_BLOCKING" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/115001747892-VoIP-Test"
              target="_blank"
            >
              {{ "ONSIP_I18N.VOIP_TEST" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/235429248-Encryption-Policy"
              target="_blank"
            >
              {{ "ONSIP_I18N.ENCRYPTION_POLICY" | translate }}</a
            >
          </li>
        </div>
        <div class="second-column faq-column">
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/208821458-When-trying-to-create-or-edit-an-App-or-Resource-nothing-happens"
              target="_blank"
            >
              {{
                "ONSIP_I18N.WHEN_TRYING_TO_CREATE_OR_EDIT_AN_APP_OR_RESOURCE_NOTHING_HAPPENS"
                  | translate
              }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687604-VoIP-Security-"
              target="_blank"
            >
              {{ "ONSIP_I18N.VOIP_SECURITY" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687594-Toll-Free-Call-Rejection"
              target="_blank"
            >
              {{ "ONSIP_I18N.TOLL_FREE_CALL_REJECTION" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204012180-Supported-Codecs-"
              target="_blank"
            >
              {{ "ONSIP_I18N.SUPPORTED_CODECS" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204012170-Reliability-and-Quality-of-Service"
              target="_blank"
            >
              {{ "ONSIP_I18N.RELIABILITY_AND_QUALITY_OF_SERVICE" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204012160-How-to-Receive-Network-Alert-Notifications"
              target="_blank"
            >
              {{ "ONSIP_I18N.HOW_TO_RECEIVE_NETWORK_ALERT_NOTIFICATIONS" | translate }}</a
            >
          </li>
        </div>
        <div class="third-column faq-column">
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687584-Getting-Listed-in-the-White-Pages"
              target="_blank"
            >
              {{ "ONSIP_I18N.GETTING_LISTED_IN_THE_WHITE_PAGES" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204304084-What-to-do-when-an-employee-leaves"
              target="_blank"
            >
              {{ "ONSIP_I18N.WHAT_TO_DO_WHEN_AN_EMPLOYEE_LEAVES" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687564-External-Number-Roll-Over"
              target="_blank"
            >
              {{ "ONSIP_I18N.EXTERNAL_NUMBER_ROLL_OVER" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204012100-Calls-Go-Straight-To-Voicemail-Even-Though-the-Phone-Is-Connected-"
              target="_blank"
            >
              {{
                "ONSIP_I18N.CALLS_GO_STRAIGHT_TO_VOICEMAIL_EVEN_THOUGH_THE_PHONE_IS_CONNECTED"
                  | translate
              }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687514-Calling-Canadian-Cell-Phones-and-Getting-a-Busy-Signal-"
              target="_blank"
            >
              {{
                "ONSIP_I18N.CALLING_CANADIAN_CELL_PHONES_AND_GETTING_A_BUSY_SIGNAL" | translate
              }}</a
            >
          </li>
        </div>
      </ul>
      } @if (isSmallOrMedBreakpoint) {
      <ul class="onsip-support-faq-list mat-body-2 onsip-grid-placement">
        <div class="first-column faq-column">
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/360026198551-SMS-Text-Messaging"
              target="_blank"
            >
              {{ "ONSIP_I18N.SMS_TEXT_MESSAGING" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/360015918652-Unable-to-Access-Phone-s-Web-Interface"
              target="_blank"
            >
              {{ "ONSIP_I18N.UNABLE_TO_ACCESS_PHONES_WEB_INTERFACE" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/115003351352-How-to-Recover-a-SIP-Address"
              target="_blank"
            >
              {{ "ONSIP_I18N.HOW_TO_RECOVER_A_SIP_ADDRESS" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/115002597511-Mimecast-email-blocking"
              target="_blank"
            >
              {{ "ONSIP_I18N.MIMECAST_EMAIL_BLOCKING" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/115001747892-VoIP-Test"
              target="_blank"
            >
              {{ "ONSIP_I18N.VOIP_TEST" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/235429248-Encryption-Policy"
              target="_blank"
            >
              {{ "ONSIP_I18N.ENCRYPTION_POLICY" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/208821458-When-trying-to-create-or-edit-an-App-or-Resource-nothing-happens"
              target="_blank"
            >
              {{
                "ONSIP_I18N.WHEN_TRYING_TO_CREATE_OR_EDIT_AN_APP_OR_RESOURCE_NOTHING_HAPPENS"
                  | translate
              }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687604-VoIP-Security-"
              target="_blank"
            >
              {{ "ONSIP_I18N.VOIP_SECURITY" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687594-Toll-Free-Call-Rejection"
              target="_blank"
            >
              {{ "ONSIP_I18N.TOLL_FREE_CALL_REJECTION" | translate }}</a
            >
          </li>
        </div>
        <div class="second-column faq-column">
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204012180-Supported-Codecs-"
              target="_blank"
            >
              {{ "ONSIP_I18N.SUPPORTED_CODECS" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204012170-Reliability-and-Quality-of-Service"
              target="_blank"
            >
              {{ "ONSIP_I18N.RELIABILITY_AND_QUALITY_OF_SERVICE" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204012160-How-to-Receive-Network-Alert-Notifications"
              target="_blank"
            >
              {{ "ONSIP_I18N.HOW_TO_RECEIVE_NETWORK_ALERT_NOTIFICATIONS" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687584-Getting-Listed-in-the-White-Pages"
              target="_blank"
            >
              {{ "ONSIP_I18N.GETTING_LISTED_IN_THE_WHITE_PAGES" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204304084-What-to-do-when-an-employee-leaves"
              target="_blank"
            >
              {{ "ONSIP_I18N.WHAT_TO_DO_WHEN_AN_EMPLOYEE_LEAVES" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687564-External-Number-Roll-Over"
              target="_blank"
            >
              {{ "ONSIP_I18N.EXTERNAL_NUMBER_ROLL_OVER" | translate }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/204012100-Calls-Go-Straight-To-Voicemail-Even-Though-the-Phone-Is-Connected-"
              target="_blank"
            >
              {{
                "ONSIP_I18N.CALLS_GO_STRAIGHT_TO_VOICEMAIL_EVEN_THOUGH_THE_PHONE_IS_CONNECTED"
                  | translate
              }}</a
            >
          </li>
          <li>
            <a
              href="https://support.onsip.com/hc/en-us/articles/203687514-Calling-Canadian-Cell-Phones-and-Getting-a-Busy-Signal-"
              target="_blank"
            >
              {{
                "ONSIP_I18N.CALLING_CANADIAN_CELL_PHONES_AND_GETTING_A_BUSY_SIGNAL" | translate
              }}</a
            >
          </li>
        </div>
      </ul>
      }
    </div>
  </div>
  <div class="footer">
    <div class="secure-identity-code">
      <div>Secure identity code:</div>
      <div class="secure-random-word">{{ secureIdCode }}</div>
      <mat-icon
        matTooltip="Use this phrase to help verify your identity when speaking with OnSIP’s Customer Success Team."
        matTooltipClass="onsip-tooltip-background"
        [matTooltipPosition]="'above'"
        class="tool-tip-icon"
        >help_outline
      </mat-icon>
    </div>
    <div class="network-status">
      @if (curSystemOperational) {
      <div class="good-network-status-icon-container">
        <mat-icon>network_check</mat-icon>
      </div>
      } @if (!curSystemOperational) {
      <div class="bad-network-status-icon-container">
        <mat-icon>network_check</mat-icon>
      </div>
      }
      <div class="network-help-text">
        @if (curSystemOperational) {
        <div class="mat-body-2">We're fully operational</div>
        } @if (!curSystemOperational) {
        <div class="mat-body-2">We're experiencing issues</div>
        }
        <a href="https://www.onsip.com/network-status" target="_blank">Learn more</a>
      </div>
    </div>
  </div>
</div>
