import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { CurrentOrganizationService } from "@onsip/common/services/api/apiParams/current-organization.service";
import {
  Organization,
  OrganizationService
} from "@onsip/common/services/api/resources/organization/organization.service";
import { views } from "@onsip/web/app/phone/views";
import { combineLatest, Subscription } from "rxjs";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { ShellNavigationWithLocator } from "../../shellNavigations/shell-navigations.component";
import { E2eLocators } from "@onsip/e2e/configs/locators";
import { AccountService } from "@onsip/common/services/api/resources/account/account.service";
import { UserService } from "@onsip/common/services/api/resources/user/user.service";
import { UserAddressService } from "@onsip/common/services/api/resources/userAddress/user-address.service";

@Component({
  selector: "onsip-org-side-nav",
  templateUrl: "./org-side-nav.component.html",
  styleUrls: ["./org-side-nav.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrgSideNavComponent implements OnInit, OnDestroy {
  views = views;
  currentOrganization = new FormControl("", { nonNullable: true });
  organizationList: Array<Organization> = [];
  shellNavigations!: Array<ShellNavigationWithLocator>;
  E2eLocators = E2eLocators;
  private unsubscriber = new Subscription();

  userService = inject(UserService);
  userAddressService = inject(UserAddressService);

  constructor(
    private orgService: OrganizationService,
    private currentOrgService: CurrentOrganizationService,
    private router: Router,
    private accountService: AccountService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.orgService.organizationBrowse();
    this.unsubscriber.add(
      combineLatest({
        orgs: this.orgService.state.pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state))
        ),
        accountId: this.accountService.currentAccountId()
      })
        .pipe(map(({ orgs, accountId }) => orgs.filter(org => org.accountId === accountId)))
        .subscribe(orgs => {
          this.organizationList = orgs;
          this.cdRef.markForCheck();
        })
    );

    this.unsubscriber.add(
      this.orgService.organization
        .pipe(distinctUntilChanged((a, b) => a.organizationId === b.organizationId))
        .subscribe(currentOrg => {
          this.currentOrganization.patchValue(currentOrg.organizationId, { emitEvent: false });
        })
    );

    this.unsubscriber.add(
      this.currentOrganization.valueChanges.pipe(distinctUntilChanged()).subscribe(orgId => {
        this.currentOrgService.switchCurrentOrg(orgId);
        this.loadUsers();
        this.router.navigate([views.ADMIN_PBX_OVERVIEW]);
      })
    );

    this.shellNavigations = [
      {
        name: "Overview",
        url: this.views.ADMIN_PBX_OVERVIEW
      },
      {
        name: "Users",
        url: this.views.USERS,
        cyLocator: E2eLocators.NAV_ADMINS_USERS_LINK
      },
      {
        name: "Phones",
        url: this.views.PHONES
      },
      {
        name: "Phone numbers",
        url: this.views.PHONE_NUMBERS
      },
      {
        name: "Inbound call settings",
        cyLocator: E2eLocators.ADMIN_INBOUND_CALL_SETTINGS_TAB,
        nestedUrls: [
          {
            name: "ACD queues",
            url: this.views.ADMIN_ACD_QUEUES,
            cyLocator: E2eLocators.ADMIN_ACD_QUEUES
          },
          {
            name: "Announcements",
            url: this.views.ADMIN_ADV_ANNOUNCEMENTS,
            cyLocator: E2eLocators.NAV_ADMINS_ANNOUNCEMENTS
          },
          {
            name: "Attendant menu",
            url: this.views.ADMIN_ATTENDANT_MENU,
            cyLocator: E2eLocators.NAV_ADMINS_ATT_MENU
          },
          {
            name: "Business hour rules",
            url: this.views.ADMIN_BHR,
            cyLocator: E2eLocators.ADMIN_BUSINESS_HOUR_RULES_PAGE_LINK
          },
          {
            name: "Call recording",
            url: this.views.ADMIN_RECORDING_RULES,
            cyLocator: E2eLocators.NAV_ADMINS_CALL_RECORDING
          },
          {
            name: "Conference suite",
            url: this.views.ADMIN_CONFERENCE_SUITE,
            cyLocator: E2eLocators.NAV_ADMINS_CONFERENCE_SUITE
          },
          {
            name: "Dial-by-name directory",
            url: this.views.ADMIN_DBN_DIRECTORY,
            cyLocator: E2eLocators.NAV_ADMINS_DBN_DIRECTORY
          },
          {
            name: "Groups",
            url: this.views.GROUPS,
            cyLocator: E2eLocators.NAV_ADMINS_GROUPS
          },
          {
            name: "Inbound bridge",
            url: this.views.ADMIN_INBOUND_BRIDGE,
            cyLocator: E2eLocators.NAV_ADMINS_INBOUND_BRIDGE
          },
          {
            name: "Music on hold",
            url: this.views.ADMIN_MUSIC_ON_HOLD,
            cyLocator: E2eLocators.NAV_ADMINS_MUSIC_ON_HOLD
          },
          {
            name: "Parking lot",
            url: this.views.ADMIN_PARKING_LOT,
            cyLocator: E2eLocators.ADMIN_PARKING_LOT_BUTTON
          },
          {
            name: "Recording library",
            url: this.views.ADMIN_RECORDING
          },
          {
            name: "Voicemail",
            url: this.views.ADMIN_VOICEMAIL,
            cyLocator: E2eLocators.NAV_ADMINS_VOICEMAILS
          }
        ]
      },
      {
        name: "Outbound call settings",
        cyLocator: E2eLocators.NAV_ADMINS_OUTBOUND_CALLS,
        nestedUrls: [
          {
            name: "E911",
            url: this.views.E911_TABLE
          },
          {
            name: "External SIP address",
            url: this.views.ADMIN_EXTERNAL_ADDRESS,
            cyLocator: E2eLocators.NAV_ADMINS_EXTERNAL_SIP_ADDRESS
          },
          {
            name: "External phone number",
            url: this.views.ADMIN_EXTERNAL_PHONE_NUMBER,
            cyLocator: E2eLocators.NAV_ADMINS_EXTERNAL_PHONE_NUMBER
          }
        ]
      },
      {
        name: "Directory",
        url: this.views.ADMIN_DIRECTORY
      },
      {
        name: "Reports",
        url: this.views.ADMIN_REPORTS
      },
      {
        name: "PBX settings",
        nestedUrls: [
          {
            name: "PBX Contact",
            url: this.views.ADMIN_PBX_SETTINGS_CONTACT
          },
          {
            name: "Blocked numbers",
            url: this.views.ADMIN_PBX_SETTINGS_BLOCKED_NUMBERS
          },
          {
            name: "Default caller ID",
            url: this.views.ADMIN_PBX_SETTINGS_DEFAULT_CALLER_ID
          },
          {
            name: "Enabled services",
            url: this.views.ADMIN_PBX_SETTINGS_ENABLED_SERVICES
          },
          {
            name: "Migrate SIP domain",
            url: this.views.ADMIN_PBX_SETTINGS_MIGRATE_SIP_DOMAIN
          },
          {
            name: "Multi-factor authentication",
            url: this.views.ADMIN_PBX_SETTINGS_MULTI_FACTOR_AUTHENTICATION
          },
          {
            name: "Recording manager",
            url: this.views.ADMIN_PBX_SETTINGS_RECORDING_MANAGER
          },
          {
            name: "Storage service",
            url: this.views.ADMIN_PBX_SETTINGS_STORAGE_SERVICE
          },
          {
            name: "Voicemail manager",
            url: this.views.ADMIN_PBX_SETTINGS_VOICEMAIL_MANAGER
          }
        ]
      }
    ];
  }

  private loadUsers() {
    this.userService.userBrowse();
    this.userAddressService.userAddressBrowseWithOrgId();
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }
}
