<div class="container mat-typography mat-app-background">
  <onsip-system-status></onsip-system-status>
  <img id="logo" [src]="'resources/img/onsip-blue.svg'" alt="OnSIP" />
  <router-outlet></router-outlet>
  @if (showContactSupport | async) {
  <a
    id="contact-support"
    routerLink="{{ entryViews.SUPPORT }}"
    class="primary-500"
    routerLinkActive="selected"
    [skipLocationChange]="true"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    {{ "ONSIP_I18N.CONTACT_SUPPORT" | translate }}
  </a>
  } @else {
  <br />
  }
  <onsip-footer></onsip-footer>
</div>
