import { Context } from "./context";
import { ApiActionType, ApiBrowseAction } from "../api-actions";
import { OnsipAPIArray, OnsipAPIBrowseAttributes, onsipApiArrayToArray } from "./xml-json";

/**
 * @template T The type for the resource
 * @template A the api action (enum member)
 * @template S the resource name (singular)
 * @template P the resource name (plural)
 */
export interface OnsipApiResponseBody<
  T = any,
  A extends ApiActionType = ApiActionType,
  S extends string = string,
  P extends string = string
> {
  Response: OnsipApiResponse<T, A, S, P>;
}

export type OnsipApiResponse<
  T = any,
  A extends ApiActionType = ApiActionType,
  S extends string = string,
  P extends string = string
> = A extends ApiBrowseAction ? OnsipApiBrowseResponse<T, A, S, P> : OnsipApiUnitResponse<T, A, S>;

/**
 * @template T The type for the resource
 * @template A the api action (enum member)
 * @template S the resource name (singular)
 */
export interface OnsipApiUnitResponse<T, A extends ApiActionType, S extends string> {
  Context: Context;
  Result: Record<A, Record<S, T>>;
}

/**
 * @template T The type for the resource
 * @template A the api action (enum member)
 * @template S the resource name (singular)
 * @template P the resource name (plural)
 */
export interface OnsipApiBrowseResponse<
  T,
  A extends ApiBrowseAction,
  S extends string,
  P extends string
> {
  Context: Context;
  Result: Record<A, Record<P, OnsipAPIArray<S, T>>> & Partial<OnsipAPIBrowseAttributes>;
}

/* these overload signatures aren't going to be used until all responses are rigorously typed
export function extractData<T, A extends ApiBrowseAction, S extends string, P extends string>(
  response: OnsipApiBrowseResponse<T, A, S, P>,
  actionName: A,
  resourceName: S,
  resourceNamePlural: P
): Array<T>;
export function extractData<T, A extends Exclude<ApiActionType, ApiBrowseAction>, S extends string>(
  response: OnsipApiUnitResponse<T, A, S>,
  actionName: A,
  resourceName: S,
  resourceNamePlural?: never
): T;*/
export function extractData<T>(
  response: any,
  actionName: ApiActionType,
  resourceName: string,
  resourceNamePlural?: string
): T {
  const isBrowse = (a: ApiActionType): a is ApiBrowseAction =>
    /Browse|DidRequest|CdrSummaryStatistic|PortInNumberCheck/.test(a);
  if (isBrowse(actionName)) {
    return Object.values(
      onsipApiArrayToArray(response.Result[actionName][resourceNamePlural as string], resourceName)
    ) as unknown as T;
  } else {
    return response.Result[actionName][resourceName];
  }
}
