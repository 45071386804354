import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { withLatestFrom, map, filter } from "rxjs/operators";

import { ApiResourceService } from "../api-resource.service";
import {
  Organization,
  ApiOrganization,
  ApiOrganizationToOrganization as clean,
  EditCallerIdParams,
  OrganizationAddParams,
  OrgMfaParams
} from "./organization";
import { ApiSessionService } from "../../api-session.service";
import { ApiAction, ApiEditAction, ApiReadAction } from "../../api-actions";
import { ApiStateStoreService } from "../../api-state-store.service";

import { sessionId } from "../../apiParams/session-id";
import { organizationId } from "../../apiParams/organization-id";
import { ParameterValue } from "../../util/api-action-description";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { userId } from "@onsip/common/services/api/apiParams/user-id";
import { accountId } from "../../apiParams/account-id";
import { Contact } from "../../apiResponse/contact";
export { Organization };

const debug = false;
const countryID = 207; //USA

@Injectable({ providedIn: "root" })
export class OrganizationService extends ApiResourceService<Organization> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "Organization", "organizationId");
    debug && this.state.subscribe(state => console.warn("OrganizationService", state));
  }

  /** public getter to get a filtered observable with just the self organization */
  get organization(): Observable<Organization> {
    return this.state.pipe(
      withLatestFrom(this.store.state.pipe(organizationId())),
      map(([substate, selfOrganizationId]) => substate.state[selfOrganizationId]),
      filter(<T>(organization: T): organization is NonNullable<T> => !!organization)
    );
  }

  organizationBrowse(params?: Record<string, ParameterValue>): ApiPromiseState<Organization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.OrganizationBrowse,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        Limit: 100,
        ...params
      }
    });

    return this.promiseState.toPromise(ApiAction.OrganizationBrowse);
  }

  organizationAdd(params: OrganizationAddParams): ApiPromiseState<Organization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.OrganizationAdd,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        UserRole: "User",
        ...params
      }
    });

    return this.promiseState.toPromise(ApiAction.OrganizationAdd);
  }

  organizationRead(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<Organization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.OrganizationRead,
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiAction.OrganizationRead);
  }

  organizationMigrate(params: {
    OldDomain: string;
    NewDomain: string;
  }): ApiPromiseState<Organization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.OrganizationMigrateDomain,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        UserId: this.store.state.pipe(userId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.OrganizationMigrateDomain);
  }

  /** flag for enabling e911 for org. Enable with Cascade as true will enable all org users' e911Provisioning flag.
   * Otherwise, admin will need to toggle e911 for specific users using UserEditE911Provisioning if Cascade is false.
   */
  organizationEdit911Provisioning(params: {
    Enabled: boolean;
    Cascade: boolean;
    OrganizationId?: string;
  }): ApiPromiseState<Organization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.OrganizationEditE911Provisioning,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.OrganizationEditE911Provisioning);
  }

  /** flag for enabling busy lamp field for organization */
  organizationEditBusyLampField(Enabled: boolean): ApiPromiseState<Organization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.OrganizationEditBusyLampField,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Enabled
      }
    });
    return this.promiseState.toPromise(ApiAction.OrganizationEditBusyLampField);
  }

  organizationEditCallerId(params: EditCallerIdParams): ApiPromiseState<Organization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.OrganizationEditCallerId,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.OrganizationEditCallerId);
  }

  organizationEditContact(params: Contact): ApiPromiseState<Organization> {
    const contact = {
      Name: params.name,
      Address: params.address,
      City: params.city,
      State: params.state,
      Zipcode: params.zipcode,
      CountryId: countryID,
      Phone: params.phone,
      Email: params.email,
      Organization: params.organization
    };
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.OrganizationEditContact,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...contact
      }
    });
    return this.promiseState.toPromise(ApiAction.OrganizationEditContact);
  }

  organizationEditMfaEmail(params: OrgMfaParams): ApiPromiseState<Organization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.OrganizationEditMfaEmail,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.OrganizationEditMfaEmail);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.OrganizationBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiOrganization>>(
              response,
              action,
              "Organization",
              "Organizations"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAction.OrganizationAdd:
      case ApiEditAction.OrganizationEditContact:
      case ApiReadAction.OrganizationRead:
      case ApiEditAction.OrganizationEditE911Provisioning:
      case ApiEditAction.OrganizationEditBusyLampField:
      case ApiEditAction.OrganizationEditCallerId:
      case ApiAction.OrganizationMigrateDomain:
      case ApiAction.OrganizationEditMfaEmail:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiOrganization>(response, action, "Organization")].map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
