<mat-card>
  <mat-card-content>
    <div class="mat-headline-5">Verify your account</div>
    <div class="mat-body-1">
      In order to continue using OnSIP, you will need to verify your account below.
    </div>
    <button
      class="confirm-mfa__verify-button"
      mat-flat-button
      color="primary"
      (click)="handleVerify()"
    >
      Verify
    </button>
    <div class="mat-caption confirm-mfa__caption-margin">
      Having issues with this page? Please contact your administrator.
    </div>
    <div class="mat-caption">
      If problems persist,
      <a
        id="contact-support"
        routerLink="{{ entryViews.SUPPORT }}"
        class="primary-500"
        routerLinkActive="selected"
        [skipLocationChange]="true"
        [routerLinkActiveOptions]="{ exact: true }"
        >contact customer support
      </a>
      .
    </div>
  </mat-card-content>
</mat-card>
